import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="FitBot" link="https://github.com/JamesHooper0820/FitBot" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
A fitness, health and well-being discord bot designed to enhance and encourage people to take care of their physical and mental health. 
    </ProjectCard>
    <ProjectCard title="Stock Pie Builder" link="https://github.com/JamesHooper0820/trading212-ark-pie-builder" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
A program to fetch the current list of shares held in an ARK ETF as well as the current list of shares offered by trading212 in their ISA wrapper - updated whenever the program is run. 
    </ProjectCard>
    <ProjectCard title="My Website" link="https://github.com/JamesHooper0820/jameshooper0820.github.io" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
The source code to my website, built using Gatsby.js, a site generator for React.js.
    </ProjectCard>
    <ProjectCard title="Investing Subscription Website (Closed Source)" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
A website made for an investing company, built as a REST API using Handlebars for its frontend and Node.js/Express.js/DynamoDB for its backend.
    </ProjectCard>
    <ProjectCard title="Auber - 2-D Infiltrator-Catching Game" link="https://threecubedstudios.github.io/auber-vega/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
A 2-D space themed game, built using Java & libGDX.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      