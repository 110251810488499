import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`I'm currently a 3rd year Computer Science student with an avid interest in software engineering and cyber security.`}</p>
    <p>{`Currently working as a Software Engineer for my year in industry.`}</p>
    <p>{`I am proficient in `}<strong parentName="p">{`Python`}</strong>{`, and familiar with `}<strong parentName="p">{`C#`}</strong>{`, `}<strong parentName="p">{`C++`}</strong>{` and `}<strong parentName="p">{`JavaScript`}</strong>{`.`}</p>
    <p>{`I'm open to any work/projects, feel free to contact me on one of the platforms below.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      