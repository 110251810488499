import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Contact Me`}</h2>
    <p><a parentName="p" {...{
        "href": "https://github.com/JamesHooper0820"
      }}>{`Github`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.linkedin.com/in/jameshooper0820/"
      }}>{`LinkedIn`}</a></p>
    <a href="mailto:jameshooper0820@gmail.com"></a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      